<template>
    <div class="hero">
        <div class="hero-container">
            <div class="left">
                <h1 class="hero-title">
                    A plataforma africana <br>
                    que transforma educadores <br>
                    em empreendedores
                </h1>
                <p class="hero-description">
                    Na Eduka África você tem todas as ferramentas que precisa <br>
                    para alcançar o real potencial do seu negócio digital. Inscreve-se <br>
                    agora e conheça todo o nosso ecossistema.
                </p>
                <div class="go" style="margin-bottom: 78px;">
                    <a href="/account/signup" style="
                        display: flex;
    justify-content: center;
    align-items: center;
                    width: 228px;
    padding: 12px 0;
    font-size: 18px;
    box-shadow: 0 1px 8px #00000029;
    " class="btn active">
                        Inscrever-se agora
                        <svg style="margin-left: 8px;" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" class="feather feather-arrow-right">
                            <line x1="5" y1="12" x2="19" y2="12" />
                            <polyline points="12 5 19 12 12 19" />
                        </svg>
                    </a>

                    <p class="yha">Você já tem uma conta? <a href="/account/signin">Acessar minha conta.</a>
                    </p>
                </div>
            </div>
            <div class="right">
                <svg xmlns="http://www.w3.org/2000/svg" id="b788e214-8c62-4533-b5b0-3b3e1bf4c8d8" data-name="Layer 1"
                    width="1161.52204" height="792.25033" viewBox="0 0 1161.52204 792.25033">
                    <title>file_sync</title>
                    <path
                        d="M584.62145,93.57415h-1.34574v-2.355h1.0093a.6729.6729,0,0,0,.67287-.67287V78.43458a.67288.67288,0,0,0-.67287-.67287h-1.0093V70.88332a14.65339,14.65339,0,0,0-14.65348-14.65344h-1.49539V54.54771a.67287.67287,0,0,0-.67287-.67287H554.34231a.67287.67287,0,0,0-.67287.67287v1.68217H334.83712a14.65339,14.65339,0,0,0-14.65348,14.65344v311.838a14.65343,14.65343,0,0,0,14.65348,14.65348H568.62223a14.65343,14.65343,0,0,0,14.65348-14.65348V107.03154h1.34574a.6729.6729,0,0,0,.67287-.67287V94.247A.67288.67288,0,0,0,584.62145,93.57415Z"
                        transform="translate(-19.23898 -53.87484)" fill="#3f3d56" />
                    <rect x="313.05499" y="11.94" width="240.89001" height="319.62" fill="#f2f2f2" />
                    <rect y="668" width="1161.52204" height="2" fill="#3f3d56" />
                    <path
                        d="M290.49114,622.12231c2.2427,76.79367-42.62747,104.93685-98.93825,106.58136q-1.962.0573-3.90636.07024-3.91709.02986-7.75808-.13046c-50.92746-2.1142-91.23951-29.14763-93.32521-100.56557-2.15845-73.90872,89.5781-169.93874,96.46088-177.04212.00619-.00018.00619-.00018.01227-.00663.26146-.27069.39536-.40611.39536-.40611S288.24861,545.33487,290.49114,622.12231Z"
                        transform="translate(-19.23898 -53.87484)" fill="var(--primary)" />
                    <path
                        d="M187.49873,717.0655l35.77056-53.19441-35.6974,58.91711.07464,5.98571q-3.91709.02986-7.75808-.13046l1.77414-76.94233-.0486-.59358.06546-.11469.16981-7.27058-39.173-56.87462,39.12677,51.433.13885,1.53678,1.34-58.13294L149.447,522.71855l33.93127,48.75366-.35417-120.43645.00076-.40086.01151.39423,2.246,94.92013,30.83647-38.55661-30.73552,46.73377.673,51.99856,28.37589-50.74862-28.27161,58.3933.37465,28.91381,41.26621-70.69222-41.14076,80.779Z"
                        transform="translate(-19.23898 -53.87484)" fill="#3f3d56" />
                    <path
                        d="M886.239,160.20719h-1.48962V119.3996A23.61832,23.61832,0,0,0,861.131,95.78124H774.6746a23.61833,23.61833,0,0,0-23.61841,23.61836V343.27326a23.61833,23.61833,0,0,0,23.61841,23.61836H861.131a23.61832,23.61832,0,0,0,23.6184-23.61836V189.25474H886.239Z"
                        transform="translate(-19.23898 -53.87484)" fill="#3f3d56" />
                    <path
                        d="M879.71974,120.78771V344.3349a17.63854,17.63854,0,0,1-17.63537,17.64153H775.21065a17.63848,17.63848,0,0,1-17.6353-17.64153V120.78771a17.63714,17.63714,0,0,1,17.6353-17.63526h10.5398a8.37878,8.37878,0,0,0,7.75928,11.54184h49.53035a8.37869,8.37869,0,0,0,7.75924-11.54184h11.28505A17.6372,17.6372,0,0,1,879.71974,120.78771Z"
                        transform="translate(-19.23898 -53.87484)" fill="#f2f2f2" />
                    <rect x="774.34773" y="79" width="73.39636" height="2.72727" fill="#ccc" />
                    <rect x="774.34773" y="87.63636" width="73.39636" height="2.72727" fill="#ccc" />
                    <rect x="774.34773" y="96.27273" width="73.39636" height="2.72727" fill="#ccc" />
                    <rect x="751.07313" y="80.6855" width="16.62899" height="16.62899" fill="#ccc" />
                    <rect x="774.34773" y="273" width="73.39636" height="2.72727" fill="#ccc" />
                    <rect x="774.34773" y="281.63636" width="73.39636" height="2.72727" fill="#ccc" />
                    <rect x="774.34773" y="290.27273" width="73.39636" height="2.72727" fill="#ccc" />
                    <rect x="751.07313" y="274.6855" width="16.62899" height="16.62899" fill="#ccc" />
                    <path
                        d="M859.14669,434.44284H650.42537v-4.30193H555.783v4.30193H346.20131a14.11841,14.11841,0,0,0-14.11843,14.11842V734.36492a14.11846,14.11846,0,0,0,14.11843,14.11848H859.14669a14.11846,14.11846,0,0,0,14.11843-14.11848V448.56126A14.11841,14.11841,0,0,0,859.14669,434.44284Z"
                        transform="translate(-19.23898 -53.87484)" fill="#3f3d56" />
                    <rect x="331.77238" y="405.51917" width="503.32529" height="283.92709" fill="#f2f2f2" />
                    <circle cx="583.00483" cy="392.61339" r="5.16231" fill="var(--primary)" />
                    <path
                        d="M887.3974,738.15876H838.27612v-3.53915a.70171.70171,0,0,0-.70173-.70174H820.73281a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70173-.70174H791.96177a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70173-.70174H763.19074a.70171.70171,0,0,0-.70173.70174v3.53915H751.963v-3.53915a.70171.70171,0,0,0-.70173-.70174H734.41971a.70171.70171,0,0,0-.70174.70174v3.53915H723.192v-3.53915a.70171.70171,0,0,0-.70174-.70174H705.64867a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70173-.70174H676.87764a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70173-.70174H533.02247a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70173-.70174H504.25144a.70171.70171,0,0,0-.70174.70174v3.53915h-10.526v-3.53915a.70172.70172,0,0,0-.70174-.70174H475.4804a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70173-.70174H446.70937a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70174-.70174H417.93833a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70173-.70174H389.1673a.70171.70171,0,0,0-.70173.70174v3.53915h-10.526v-3.53915a.70171.70171,0,0,0-.70173-.70174H360.39627a.70171.70171,0,0,0-.70174.70174v3.53915H327.41484a16.84159,16.84159,0,0,0-16.84158,16.84158v7.61537a16.84158,16.84158,0,0,0,16.84158,16.84154H887.3974A16.84158,16.84158,0,0,0,904.239,762.61571v-7.61537A16.84159,16.84159,0,0,0,887.3974,738.15876Z"
                        transform="translate(-19.23898 -53.87484)" fill="#3f3d56" />
                    <rect x="534.78847" y="427.7565" width="141.24028" height="5.24823" fill="#e6e6e6" />
                    <rect x="534.78847" y="444.37589" width="141.24028" height="5.24823" fill="#e6e6e6" />
                    <rect x="534.78847" y="460.99527" width="141.24028" height="5.24823" fill="#e6e6e6" />
                    <rect x="534.78847" y="427.7565" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="534.78847" y="444.37589" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="534.78847" y="460.99527" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="490" y="431" width="32" height="32" fill="#ccc" />
                    <rect x="534.78847" y="614.7565" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="534.78847" y="631.37589" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="534.78847" y="647.99527" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="490" y="618" width="32" height="32" fill="#ccc" />
                    <path
                        d="M943.51144,362.9222a53.19709,53.19709,0,0,0-31.60589-16.67841l5.1983-4.6958-5.75067-.2904-7.11457,6.4306,6.43061,7.11462,5.75067.29041-4.30878-4.76428a49.359,49.359,0,0,1-7.87213,98.08691v4a53.36418,53.36418,0,0,0,39.27246-89.49365Z"
                        transform="translate(-19.23898 -53.87484)" fill="#3f3d56" />
                    <path
                        d="M252.96652,362.9222a53.19709,53.19709,0,0,1,31.6059-16.67841l-5.19831-4.6958,5.75067-.2904,7.11457,6.4306-6.43061,7.11462-5.75067.29041,4.30878-4.76428a49.359,49.359,0,0,0,7.87213,98.08691v4a53.36418,53.36418,0,0,1-39.27246-89.49365Z"
                        transform="translate(-19.23898 -53.87484)" fill="#3f3d56" />
                    <rect x="385.27409" y="27.7565" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="385.27409" y="44.37589" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="385.27409" y="60.99527" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="340.48563" y="31" width="32" height="32" fill="#ccc" />
                    <rect x="384.78847" y="276.7565" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="384.78847" y="293.37589" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="384.78847" y="309.99527" width="141.24028" height="5.24823" fill="#ccc" />
                    <rect x="340" y="280" width="32" height="32" fill="#ccc" />
                    <path
                        d="M985.771,381.79661l31.894-46.30584s27.77274-22.26912,19.81265-26.60525-28.80015,15.99258-28.80015,15.99258-60.12445,46.09338-50.20994,52.52635S985.771,381.79661,985.771,381.79661Z"
                        transform="translate(-19.23898 -53.87484)" fill="#c5a091" />
                    <path
                        d="M990.40617,320.98636h41.30367a144.46152,144.46152,0,0,0,0-49.24667H990.40617A88.61132,88.61132,0,0,1,990.40617,320.98636Z"
                        transform="translate(-19.23898 -53.87484)" fill="var(--primary)" />
                    <path
                        d="M1097.79125,323.33578c-12.41227-20.9729-36.969-21.95033-36.969-21.95033s-23.92909-3.06-39.27945,28.882c-14.30776,29.77255-34.05429,58.51855-3.179,65.48809l5.577-17.358,3.45379,18.65031a120.80742,120.80742,0,0,0,13.21037.22578c33.06492-1.06753,64.55425.31233,63.54034-11.55275C1102.79746,369.94785,1109.73434,343.51589,1097.79125,323.33578Z"
                        transform="translate(-19.23898 -53.87484)" fill="#2f2e41" />
                    <path d="M1031.79575,452.61588l-15.90018,36.57041,55.65063,8.7451s26.23529-35.7754,28.62032-38.16043Z"
                        transform="translate(-19.23898 -53.87484)" fill="#c5a091" />
                    <circle cx="1038.79207" cy="289.82482" r="29.41533" fill="#c5a091" />
                    <path
                        d="M1050.876,365.1649s13.51515,30.21034,11.92513,33.39037,28.62032-16.69518,28.62032-16.69518-12.72014-21.46524-11.92513-24.64528Z"
                        transform="translate(-19.23898 -53.87484)" fill="#c5a091" />
                    <path
                        d="M1095.39647,377.885s23.85026-13.51515,37.36542,23.85027c0,0,44.5205,52.47059,36.57041,62.0107s-38.16043-23.05526-38.16043-23.05526l-19.08022-19.87523s-27.0303-7.15508-24.64527-22.26025S1095.39647,377.885,1095.39647,377.885Z"
                        transform="translate(-19.23898 -53.87484)" fill="#c5a091" />
                    <path
                        d="M1039.74584,396.17025l-55.65063-15.90018s-29.41533-11.92513-28.62032-4.77,54.06061,47.70053,71.55081,45.3155S1039.74584,396.17025,1039.74584,396.17025Z"
                        transform="translate(-19.23898 -53.87484)" fill="#c5a091" />
                    <path
                        d="M1071.5462,390.60519s-18.28521-2.385-22.26025,1.59-11.52763.39751-11.52763.39751L1025.03817,415.648s-9.1426,13.11764-1.98752,25.83779-3.18,29.41533-3.18,29.41533,57.24064,0,69.16577,6.36007,26.29777-53.068,26.29777-53.068-14.77014-18.08528-10.79509-29.21541,1.59-19.87522,1.59-19.87522l-17.4902,1.59Z"
                        transform="translate(-19.23898 -53.87484)" fill="#d0cde1" />
                    <polygon points="983.936 688.124 990.297 749.34 1014.942 736.62 1006.992 683.354 983.936 688.124"
                        fill="#c5a091" />
                    <polygon points="1076.952 687.329 1097.648 742.67 1117.523 728.36 1092.853 674.609 1076.952 687.329"
                        fill="#c5a091" />
                    <path
                        d="M1034.18078,769.82445a49.64912,49.64912,0,0,1-5.56506,12.72014c-3.97505,6.36007-13.51516,3.18-13.51516,3.18s-11.13012-8.7451-13.51515-4.77006-15.10517,23.85027-15.10517,23.85027-42.13547,34.98039-6.36007,40.54546,42.93048-20.67024,42.93048-20.67024,27.03031-9.5401,27.82532-19.08021S1034.18078,769.82445,1034.18078,769.82445Z"
                        transform="translate(-19.23898 -53.87484)" fill="#2f2e41" />
                    <path
                        d="M1117.65672,781.41446s-12.72015-3.975-11.92514,2.385,5.56506,31.80036,5.56506,31.80036-8.74509,35.7754,15.90018,29.41533,25.44029-40.54546,25.44029-40.54546,12.72014-45.31551,3.18-46.11051-32.59536-3.18-32.59536-3.18S1137.53194,778.23443,1117.65672,781.41446Z"
                        transform="translate(-19.23898 -53.87484)" fill="#2f2e41" />
                    <polygon
                        points="1073.065 267.439 1048.804 254.731 1015.301 259.93 1008.369 290.545 1025.624 289.881 1030.444 278.634 1030.444 289.696 1038.406 289.389 1043.027 271.483 1045.916 290.545 1074.22 289.967 1073.065 267.439"
                        fill="#2f2e41" />
                    <path
                        d="M1080.2913,495.54636l-66.78075-15.10517s-33.39038,57.24065-38.16043,104.94118-26.2353,162.18183,7.95009,163.77184,54.0606,1.59,55.65062-1.59-16.69519-128.79145-16.69519-128.79145,38.95544,154.23173,60.42068,143.89662,45.31551-23.85027,44.5205-31.80036-52.47059-92.221-52.47059-92.221l7.15508-73.14082s8.7451-29.41533-6.36007-56.44563Z"
                        transform="translate(-19.23898 -53.87484)" fill="#2f2e41" />
                    <path
                        d="M1032.162,475.67161h41.30366a144.46158,144.46158,0,0,0,0-49.24668H1032.162A88.61154,88.61154,0,0,1,1032.162,475.67161Z"
                        transform="translate(-19.23898 -53.87484)" fill="var(--primary)" />
                    <path
                        d="M1152.63711,439.89574l-69.96079-1.59s-31.00535-23.85027-31.80035-9.54011,32.59536,22.26025,32.59536,22.26025,71.78242,25.75844,82.79674,14.46924Z"
                        transform="translate(-19.23898 -53.87484)" fill="#c5a091" />
                    <circle cx="375.86364" cy="174.0315" r="23.86364" fill="var(--primary)" />
                    <circle cx="439.5" cy="174.0315" r="19.5" fill="var(--primary)" />
                    <circle cx="503.13636" cy="174.0315" r="11.86364" fill="var(--primary)" />
                    <circle cx="768.97799" cy="177.02031" r="12.53648" fill="var(--primary)" />
                    <circle cx="802.40861" cy="177.02031" r="9.96711" fill="var(--primary)" />
                    <circle cx="835.83923" cy="177.02031" r="6.53648" fill="var(--primary)" />
                    <circle cx="583" cy="538.0315" r="56" fill="var(--primary)" />
                    <polygon
                        points="603.834 550.347 582.946 508.159 562.166 550.401 573.908 550.386 573.908 567.904 592.083 567.904 592.083 550.362 603.834 550.347"
                        fill="#d0cde1" />
                </svg>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "Hero"
}
</script>
<style scoped>
.hero {
    margin-top: 70px;
    background-color: #fff;
}

.hero-container {
    display: flex;
    align-items: center;
    padding: 0 6%;
}

.hero-container .left {
    width: 54%;
}

.hero-container .right {
    width: 46%;
}

.right svg {
    width: 500px;
    height: 600px;
}

.hero-title {
    margin-top: 78px;
    font-size: 2.875rem;
    color: #171a2b !important;
    line-height: 1.25;
    margin-bottom: 20px;
}

.hero-description {
    font-size: 18px;
    margin-bottom: 30px;
    font-weight: 400;
    color: #171a2b;
    line-height: 1.5;
}

.yha {
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #171a2b;
}

.yha a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 500;
}

.yha a:hover {
    text-decoration: underline;
}

@media(max-width: 767px) {
    .hero-title {
        font-size: 2rem;
        line-height: 1.3;
    }
    .hero-description {
        font-size: 16px;
    }
    .hero-container .right {
        display: none;
    }
    .hero-container .left {
        width: 100%;
    }
    .btn.active {
        width: 100% !important;
        font-size: 16px !important;
    }
}</style>
